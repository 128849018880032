import React from 'react'
import Index from '../../components/media'

 const marketing = (props) => {
    return (
        <>
            <Index />
        </>
    )
}
export default marketing;