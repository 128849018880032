import React, {useRef, useEffect, useState} from "react";
import Header from "./Header";
import Layout from '../Layout'
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import Photography from "./Photography";
import Power from "./Power";
import Videography from "./Videography";
import Contact from "../contact";

const Index = () => {

  const underlay = () => {
    return (
      <>
        <Background>
          <StaticImage
            src="../../images/media_hero.jpg"
            alt="Marketing Services"
            loading="eager"
          />
        </Background>
        <Foreground />
      </>
    );
  }

  return (
    <Layout underlay={underlay()} metatags={{
      title: "JLTech Media - Photography, Videography & Social Media > Content Creators",
      desc: `They say pictures are worth a thousand words, and when you are marketing, every word counts.
            That is why videos and photos are so important for businesses. Customers want to see the product,
            examine it, watch it in action. Photos and video production equals more business for your business.`,
      keywords: `photographers near me, videographers near me, social media manager near me, photographer spokane, 
      videographer spokane, 'photographer coeur d'alene, videographer coeur d'alene, social media spokane, 
      social media coeur d'alene, commercials, hulu ads, promotional clips, video editing`
    }} snap>
      <Header id="header" />
      <Power id="power"  />
      <Videography id="videography"  />
      <Photography id="photography" />
      <Chat />
    </Layout>
  );
};

export default Index;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;

const Background = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, #412ddb 0%, #3a28c500 100%) 0% 0% no-repeat padding-box;
`;

const HorizontalScroll = styled.section`
  position: relative;
  background: #FFFFFF;
  height: 100vh;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
`;

const HorizontalScrollInner = styled.div`
  background: transparent;
  display: flex;
  flex-direction: row;
`;