import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT, ImageRight, H3, H4, H5} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";
import {Carousel, Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import ReadMore from "../ReadMore";
import {getYoutubeId} from "../../helpers";
import YouTube from "react-youtube";
import {MarketingUnified} from "../icons/MarketingUnified";
import {AiOutlineArrowLeft} from "@react-icons/all-files/ai/AiOutlineArrowLeft";
import {AiOutlineArrowRight} from "@react-icons/all-files/ai/AiOutlineArrowRight";

const Photography = (props, ref) => {

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      origin: typeof window !== 'undefined' && window ? window.location.origin : null,
    },
  };

  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <FirstRow justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title className="animate-in-slide-up">Photography.</Title>
            <Paragraph className="animate-in-slide-up">
              An absolute necessity for businesses in today's market, photos are a part of the foundation that
              create a strong brand. Product photos, social media, internal documentation – photos are used in
              almost every aspect of business. JL Tech can reduce strain on your business by providing professional
              and creative photography services for all your needs, so you can focus on what matters.
            </Paragraph>
            <ReadMore className="animate-in-slide-up">Learn More</ReadMore>
          </Col>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageRight className="animate-in-slide-left">
              <StaticImage
                src={"../../images/media_video1.jpg"}
                layout="constrained"
                alt="unique design for your needs"
                loading="eager"
                placeholder="blurred"
              />
            </ImageRight>
          </Col>
        </FirstRow>
        <SecondRow justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SubTitle>
              <Icon className="animate-in-slide-up">
                <MarketingUnified />
              </Icon><div className="animate-in-slide-up">Businesses</div></SubTitle>
            <Paragraph className="animate-in-slide-up">
              Team photos and portraits, internal documentation, whatever your business needs, we can do.
              Our experienced photographers will get the right shots so you can focus on your business.
            </Paragraph>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SubTitle>
              <Icon className="animate-in-slide-up">
                <MarketingUnified />
              </Icon><div className="animate-in-slide-up">Products</div></SubTitle>
            <Paragraph className="animate-in-slide-up">
              Showcasing your products is important, and our photographers are skilled at creating unique imagery
              that fits your brand. Show off your products in the best way to catch the eyes of potential customers.
            </Paragraph>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SubTitle>
              <Icon className="animate-in-slide-up">
                <MarketingUnified />
              </Icon><div className="animate-in-slide-up">Social Media</div></SubTitle>
            <Paragraph className="animate-in-slide-up">
              Harness the power of social media by showing off your business in the best light.
              The right photos can not only get eyes on your products but express the personality of your business.
            </Paragraph>
          </Col>
        </SecondRow>
        <ThirdRow justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <VideoCarousel arrows prevArrow={<AiOutlineArrowLeft />} nextArrow={<AiOutlineArrowRight />}>
            <Video>
              <YouTube containerClassName="youtube-fullpage" videoId={getYoutubeId('https://youtu.be/_Gcd6IgsecQ')} opts={opts} />
            </Video>
            <Video>
              <YouTube containerClassName="youtube-fullpage" videoId={getYoutubeId('https://youtu.be/_Gcd6IgsecQ')} opts={opts} />
            </Video>
            <Video>
              <YouTube containerClassName="youtube-fullpage" videoId={getYoutubeId('https://youtu.be/_Gcd6IgsecQ')} opts={opts} />
            </Video>
            <Video>
              <YouTube containerClassName="youtube-fullpage" videoId={getYoutubeId('https://youtu.be/_Gcd6IgsecQ')} opts={opts} />
            </Video>
          </VideoCarousel>
        </ThirdRow>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Photography);

const Wrapper = styled(Section)`
  position: relative;
  background: rgba(26, 18, 88, .75);
  height: 240vh;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 220vh;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const VideoCarousel = styled(Carousel)`
  position: relative;
  width: 100%;
  height: 100%;
  
  .slick-arrow {
    top: auto;
    bottom: -60px;
    color: #fff;
    &.slick-prev {
      left: 0px;
    }
    
    &.slick-next {
      left: 300px;
    }
    
    &:hover {
      color: #FF3D00;
    }
  }
  
  .slick-dots {
    right: auto;
    top: auto;
    bottom: -51px;
    left: 40px;
    width: 240px;
    margin: 0;
    
    li {
      display: flex;
      flex-grow: 1;
      width: auto;
      margin: 0;
      button {
        background: rgba(255, 255, 255, 1);
      }
        
      &.slick-active {
        button {
          background: #FF3D00;
        }
      }
    }
  }
`;

const Video = styled.div`
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 80vh;
  }
`;

const FirstRow = styled(Row)`
  align-items: center;
  width: 100%;
  padding-top: 50px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 80vh;
  }
`;

const SecondRow = styled(Row)`
  width: 100%;
  align-items: flex-start;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 40vh;
  }
`;

const ThirdRow = styled.div`
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 100vh;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 80px;
  margin-right: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 4vw;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;

const SubTitle = styled.h3`
  ${H5}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #fff;
`;
