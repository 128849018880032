import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";

const Power = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Title className="animate-in-slide-up">The Power of the Eyes.</Title>
          <Paragraph className="animate-in-slide-up">
            They say pictures are worth a thousand words, and when you are marketing, every word counts.
            That is why videos and photos are so important for businesses. Customers want to see the product,
            examine it, watch it in action. Photos and video production equals more business for your business.
          </Paragraph>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Power);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, .5);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 60%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 50%;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;
